import styled from "styled-components";
import {
  Route,
  Routes,
  Navigate,
  useParams,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { PageCastle } from "./pages/PageCastle";
import { PageDates } from "./pages/PageDates";
import { PageCart } from "./pages/PageCart";
import { StoreClosed } from "./pages/StoreClosed";

import { NavBar } from "./components/NavBar";
import { CastleError } from "./components/CastleError";
import { Castles } from "./lib/Data";
import { Analytics } from "./lib/Analytics";

const App = () => {
  const { i18n } = useTranslation();
  const [activeCastle, setActiveCastle] = useState(undefined);
  const [cartId, setCartId] = useState(undefined);
  const [cart, setCart] = useState(undefined);
  const [cartLoading, setCartLoading] = useState(true);
  const [store, setStore] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [selectedTimeslot, setSelectedTimeslot] = useState(undefined);
  const [storeLocked, setStoreLocked] = useState(true);

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  useEffect(() => {
    if (params.access === "vouchers") {
      localStorage.setItem("access-token", "vouchers");
    }

    const now = +new Date();
    const openStoreDate = +new Date("2024-07-15T00:00:00");
    const closedStoreDate = +new Date("2024-12-31T00:00:00");

    if (now > closedStoreDate) {
      return;
    }

    if (localStorage.getItem("access-token") || now > openStoreDate) {
      setStoreLocked(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Check for cart id in local storage
  useEffect(() => {
    const cartIdInSession = localStorage.getItem("esq-cart");
    // Update state
    if (cartIdInSession) {
      setCartId(cartIdInSession);
    }
  }, []);

  // Fetch cart from API when id is set
  useEffect(() => {
    if (cartId) fetchCart();
  }, [cartId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedTimeslot) return;
    localStorage.setItem("esq-timeslot", JSON.stringify(selectedTimeslot));
  }, [selectedTimeslot]);

  // Set selected date / selected timeslot when cart items are loaded
  useEffect(() => {
    if (!cart || !cart.items.types.length || selectedDate || selectedTimeslot)
      return;
    let cartTimeslot = cart.items.types[cart.items.types.length - 1].show;

    if (!cartTimeslot) {
      // See if timeslot is stored in local storage
      cartTimeslot = JSON.parse(localStorage.getItem("esq-timeslot"));
    }
    if (!cartTimeslot) return;
    const cartShowDate = new Date(cartTimeslot.date.start.replace(/-/g, "/"));
    const newSelectedDate = new Date(
      cartShowDate.getFullYear() +
        "/" +
        (cartShowDate.getMonth() + 1) +
        "/" +
        cartShowDate.getDate()
    );
    setSelectedDate(newSelectedDate);
    setSelectedTimeslot(cartTimeslot);
  }, [cart]); // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize cart when castle page is loaded
  const fetchStore = () => {
    let storeUrl = `/api/store/${activeCastle.uri}?language=${i18n.languages[0]}`;
    if (cartId) storeUrl += `&cart=${cartId}`;
    fetch(storeUrl)
      .then((response) => {
        if (!response.ok) {
          return response
            .json()
            .then((data) => {
              throw data;
            })
            .catch((err) => {
              throw err;
            });
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setStore(data.edition);
        setCartId(data.edition.cart.cartid);
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  };

  const fetchCart = () => {
    if (!cartId) return;
    setCartLoading(true);
    fetch(`/api/cart/${cartId}?language=${i18n.languages[0]}`)
      .then((response) => {
        if (!response.ok) {
          return response
            .json()
            .then((data) => {
              throw data;
            })
            .catch((err) => {
              throw err;
            });
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setCart(data.cart);
        setCartLoading(false);
        localStorage.setItem("esq-cart", cartId);
      })
      .catch((err) => {
        setCartLoading(false);
        if (typeof err.error !== "undefined") {
          switch (err.error) {
            case "cart_not_found":
            case "cart_expired":
            case "cart_fulfilled":
              // Should show error, for now clear cart and reload
              localStorage.removeItem("esq-cart");
              window.location.reload();
              break;
            default:
              break;
          }
        }
      });
  };

  // Google Analytics
  Analytics(store);

  if (storeLocked) {
    return (
      <Routes>
        <Route path="/:locale" element={<StoreClosed />} />
        <Route
          path="/"
          // element={<Navigate to={`/${i18n.languages[0]}`} replace={true} />}
          element={<Navigate to={`/nl`} replace={true} />}
        />
      </Routes>
    );
  } else {
    return (
      <div className="App">
        <Helmet htmlAttributes={{ lang: i18n.languages[0] }}>
          <meta charSet="utf-8" />
          <title>
            {i18n.languages[0] === "fr"
              ? "Réservez vos billets en ligne - Magie de Noël"
              : "Online tickets reserveren - Kerstmagie"}
          </title>
          <meta
            name="description"
            content={
              i18n.languages[0] === "fr"
                ? "Réservez dès maintenant vos billets pour la Magie de Noël 2024"
                : "Boek nu uw tickets voor Kerstmagie 2024"
            }
          />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://tickets.kerstmagie.be/${
              i18n.languages[0] === "fr" ? "fr" : "nl"
            }`}
          />
          <meta
            property="og:title"
            content={
              i18n.languages[0] === "fr"
                ? "Réservez vos billets en ligne - Magie de Noël"
                : "Online tickets reserveren - Kerstmagie"
            }
          />
          <meta
            property="og:description"
            content={
              i18n.languages[0] === "fr"
                ? "Réservez dès maintenant vos billets pour la Magie de Noël 2024"
                : "Boek nu uw tickets voor Kerstmagie 2024"
            }
          />
          <meta
            property="og:image"
            content={
              i18n.languages[0] === "fr"
                ? "https://tickets.kerstmagie.be/images/magiedenoel_share.jgp"
                : "https://tickets.kerstmagie.be/images/kerstmagie_share.jpg"
            }
          />

          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`https://tickets.kerstmagie.be/${
              i18n.languages[0] === "fr" ? "fr" : "nl"
            }`}
          />
          <meta
            property="twitter:title"
            content={
              i18n.languages[0] === "fr"
                ? "Réservez vos billets en ligne - Magie de Noël"
                : "Online tickets reserveren - Kerstmagie"
            }
          />
          <meta
            property="twitter:description"
            content={
              i18n.languages[0] === "fr"
                ? "Réservez dès maintenant vos billets pour la Magie de Noël 2024"
                : "Boek nu uw tickets voor Kerstmagie 2024"
            }
          />
          <meta
            property="twitter:image"
            content={
              i18n.languages[0] === "fr"
                ? "https://tickets.kerstmagie.be/images/magiedenoel_share.jpg"
                : "https://tickets.kerstmagie.be/images/kerstmagie_share.jpg"
            }
          />
        </Helmet>
        <StoreGrid>
          <NavBar fetchCart={fetchCart} />
          <StoreContainer>
            <Routes>
              <Route path="/:locale" element={<PageCastle cart={cart} />} />
              <Route
                path="/:locale/:castle"
                element={
                  <CastleWrapper
                    activeCastle={activeCastle}
                    onSelectCastle={setActiveCastle}
                    cart={cart}
                    fetchStore={fetchStore}
                  />
                }
              >
                <Route
                  index
                  element={
                    <PageDates
                      activeCastle={activeCastle}
                      store={store}
                      selectedDate={selectedDate}
                      selectedTimeslot={selectedTimeslot}
                      setSelectedDate={setSelectedDate}
                      setSelectedTimeslot={setSelectedTimeslot}
                    />
                  }
                />
                <Route
                  path="cart"
                  element={
                    <PageCart
                      activeCastle={activeCastle}
                      store={store}
                      cart={cart}
                      cartLoading={cartLoading}
                      fetchCart={fetchCart}
                      selectedTimeslot={selectedTimeslot}
                    />
                  }
                />
              </Route>
              <Route
                path="/"
                element={
                  // <Navigate to={`/${i18n.languages[0]}`} replace={true} />
                  <Navigate to={`/nl`} replace={true} />
                }
              />
            </Routes>
          </StoreContainer>
        </StoreGrid>
      </div>
    );
  }
};

const CastleWrapper = ({ activeCastle, onSelectCastle, cart, fetchStore }) => {
  const { castle, locale } = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { languages } = i18n;
  const activeLanguage = languages[0];

  useEffect(() => {
    if (locale !== "nl") {
      navigate(`/nl`, { replace: true });
    }
    if (activeLanguage !== locale) {
      i18n.changeLanguage(locale);
    }
  }, [locale, activeLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Find castle matching uri
    const selectedCastle = Castles.find(
      (possibleCastle) => possibleCastle.uri === castle
    );
    if (!selectedCastle) {
      // Navigate to home if castle is invalid
      navigate(`/${activeLanguage}`, { replace: false });
    } else {
      // Push to parent state
      onSelectCastle(selectedCastle);
    }
  }, [castle, navigate, activeLanguage, onSelectCastle]);

  useEffect(() => {
    if (activeLanguage !== locale) {
      i18n.changeLanguage(locale);
    }
  }, [i18n, locale, activeLanguage]);

  // Load the cart when active castle is set and uri matches
  useEffect(() => {
    if (activeCastle && activeCastle.uri === castle) fetchStore();
  }, [activeCastle]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!activeCastle) return null;

  return (
    <div>
      <CastleError cart={cart} activeCastle={activeCastle} />
      <Outlet />
    </div>
  );
};

const StoreGrid = styled.div`
  padding-left: 400px;

  @media (max-width: 1100px) {
    padding: 0;
  }
`;

const StoreContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 48px;

  @media (max-width: 860px) {
    padding: 48px 24px;
  }
`;

export default App;
